import React from 'react';
import {injectIntl} from 'gatsby-plugin-intl';

import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles';

import Layout from 'components/layout/Layout';
import Seo from 'components/common/Seo';
import {login} from 'services/auth';
import themeMuiV5 from 'components/common/ThemeMuiV5';


const Callback = ({intl}) => {
  login();

  return( 
    <ThemeProviderV5 theme={themeMuiV5}>
    <Layout>
        <Seo title={intl.formatMessage({ id: "title" })}></Seo>
        <p>{intl.formatMessage({ id: "loading" })}</p>
    </Layout>
    </ThemeProviderV5>
  )
}

export default injectIntl(Callback)
